@import '../core.css';

.root {
  display: grid;
  flex-direction: column;
  flex-basis: 100%;
  align-items: center;
  width: 360px;
  margin-inline: auto;
  padding-inline: 8px;
}

@media (--gte-tablet) {
  .root {
    width: 834px;
    padding-inline: 97px;
    margin-inline: auto;
  }
}

@media (--gte-desktop) {
  .root {
    width: 1440px;
    padding-inline: 400px;
    margin-inline: auto;
  }
}

.heading,
.fancyHeading {
  margin-block-start: 0;
  margin-block-end: 45px;
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: var(--color-base-99);
}

@media (--gte-tablet) {
  .heading,
  .fancyHeading {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (--gte-desktop) {
  .heading,
  .fancyHeading {
    font-size: 48px;
    line-height: 56px;
  }
}

.fancyHeading {
  background: radial-gradient(
    166% 259% at -87px -2px,
    #22fff2 19.8%,
    #0a23fc 40.8%,
    #030f2f 67.25%,
    #fb36ff 87.34%
  );
  background-clip: text;
  color: transparent;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-base-00);
  box-shadow: 8px 40px 80px -40px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  padding: 32px;
}

.emailLabel {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--color-base-09);
  font-weight: 500;
}

.socialButtonGroup {
  display: flex;
  gap: 12px;
}

.socialButton {
  border-radius: 16px;
}
