.root {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  border-radius: 16px;
  background: var(--color-primary-500);
  color: var(--color-base-00);
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: start;
  transform: scale(1);
  transition-property: background-color, transform;
  transition-duration: 200ms;
}

.root_medium {
  padding: 12px 32px;
}

.root_big {
  padding: 16px 40px;
}

.root:hover {
  background-color: var(--color-primary-800);
  transform: scale(1.05);
}

.root:active {
  background-color: var(--color-primary-900);
  transform: scale(1);
}
