@import '../core.css';

.statistics {
  margin-block-start: 64px;
}

.exploreCrypto {
  margin-block-start: 64px;
}

@media (--gte-desktop) {
  .exploreCrypto {
    margin-block-start: 128px;
  }
}

.connectWeb3 {
  margin-block-start: 64px;
}

.swapTokens {
  margin-block-start: 64px;
}

@media (--gte-desktop) {
  .swapTokens {
    margin-block-start: 128px;
  }
}

.otherBenefits {
  margin-block-start: 64px;
}

@media (--gte-desktop) {
  .otherBenefits {
    margin-block-start: 128px;
  }
}

.installMobileApp {
  margin-block-start: 64px;
}

@media (--gte-desktop) {
  .installMobileApp {
    margin-block-start: 128px;
  }
}

.installWebExtension {
  margin-block-start: 64px;
}

.gotAnyQuestions {
  margin-block-start: 64px;
}

@media (--gte-desktop) {
  .gotAnyQuestions {
    margin-block-start: 128px;
  }
}
