@import '../core.css';

.otherBenefits {
  width: 312px;
  margin-inline: auto;
}

@media (--gte-tablet) {
  .otherBenefits {
    width: 754px;
  }
}

@media (--gte-desktop) {
  .otherBenefits {
    width: 1184px;
  }
}

.sectionHeading {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: var(--color-base-09);
  margin-top: 0;
  margin-bottom: 32px;
}

@media (--gte-tablet) {
  .sectionHeading {
    margin-bottom: 56px;
    font-size: 40px;
    line-height: 48px;
  }
}

@media (--gte-desktop) {
  .sectionHeading {
    margin-bottom: 64px;
    font-size: 48px;
    line-height: 56px;
  }
}

.gradientText {
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(175deg, #030f2f 30%, #fb36ff 60%);
}

.otherBenefitsList {
  display: grid;
  gap: 32px;
  justify-content: space-between;
}

@media (--gte-tablet) {
  .otherBenefitsList {
    grid-template-columns: repeat(2, 280px);
    gap: 48px;
  }
}

@media (--gte-desktop) {
  .otherBenefitsList {
    grid-template-columns: repeat(3, 280px);
    gap: 80px;
  }
}

.otherBenefitsListItemIcon {
  width: 64px;
  height: 64px;
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
}

.otherBenefitsListItemHeading {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--color-base-09);
}

.otherBenefitsListItemDescription {
  font-size: 14px;
  line-height: 16px;
  color: var(--color-base-07);
}
