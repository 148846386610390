@import '../core.css';

.pageHeading {
  font-weight: 500;
  font-size: 48px;
  color: var(--color-base-99);
  text-align: center;
  margin-block: 0;
}

@media (--gte-tablet) {
  .pageHeading {
    font-size: 80px;
  }
}

.anchors {
  margin-top: 8px;
  margin-bottom: 16px;
  padding-block: 16px;
  max-width: 100vw;
  display: flex;
  gap: 24px;
  overflow-y: auto;
  scrollbar-width: none;
  position: sticky;
  top: 0;
  background: var(--color-base-01);
  z-index: 2;

  --container-width: 344px;
}
.anchors::-webkit-scrollbar {
  display: none;
}

@media (--gte-tablet) {
  .anchors {
    --container-width: 794px;
    margin-bottom: 24px;
  }
}

@media (--gte-desktop) {
  .anchors {
    --container-width: 1184px;
    margin-bottom: 80px;
  }
}

.anchorButton {
  white-space: nowrap;
}

.anchorButton:not(.anchorButton_active) {
  background-color: var(--color-primary-50);
  color: var(--color-base-99);
}

.anchorButton:first-child {
  margin-left: calc((100vw - var(--container-width)) / 2);
}
.anchorButton:last-child {
  margin-right: calc((100vw - var(--container-width)) / 2);
}

.anchorGetKeeperOnMobile,
.anchorGetKeeperForYourDesktop,
.anchorIntegrateSwapWidget,
.anchorDiscoverDomains {
  position: relative;
  top: -100px;
}
