@import '../core.css';

.root {
  display: grid;
  grid-template-areas: 'illustration' 'content';
  max-width: 344px;
  margin-inline: auto;
  margin-top: 52px;
  position: relative;
}

@media (--gte-tablet) {
  .root {
    grid-template-areas: 'content illustration';
    margin-top: 88px;
    max-width: 794px;
  }
}

@media (--gte-desktop) {
  .root {
    margin-top: 128px;
    max-width: 1184px;
  }
}

.content {
  grid-area: content;
  max-width: 382px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
}

@media (--gte-tablet) {
  .content {
    margin-top: 0;
    align-items: flex-start;
  }
}

.illustration {
  grid-area: illustration;
  display: flex;
  justify-content: flex-end;
}

.illustrationImage {
  box-shadow: 8px 40px 80px -16px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.pictureShadow {
  display: none;
  position: absolute;
  width: 242px;
  height: 242px;
  left: 134px;
  top: 0;
  background: #1f5af6;
  opacity: 0.21;
  filter: blur(150px);
}

@media (--gte-desktop) {
  .pictureShadow {
    display: block;
  }
}

.fancyHeading {
  background: radial-gradient(
    112% 440% at -1px -50px,
    #22fff2 19.8%,
    #0a23fc 40.8%,
    #030f2f 67.25%,
    #fb36ff 87.34%
  );
  background-clip: text;
  color: transparent;
}
