@import '../../core.css';

.googlePlayLink {
  width: 141px;
  height: 48px;
  background: url(./googlePlay.svg) no-repeat;
}

.appStoreLink {
  width: 141px;
  height: 48px;
  background: url(./appStore.svg) no-repeat;
}
