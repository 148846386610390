@import '../core.css';

.root {
  max-width: 344px;
  margin-inline: auto;
  padding: 40px 16px;
  background: linear-gradient(113.5deg, #3a3a43 6.27%, #272737 65.15%);
  box-shadow: 8px 40px 80px -16px rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  text-align: center;
  color: var(--color-base-00);
}

@media (--gte-tablet) {
  .root {
    max-width: 794px;
  }
}

@media (--gte-desktop) {
  .root {
    max-width: 1184px;
  }
}

.heading {
  font-weight: 500;
  font-size: 32px;
  margin-block: 0;
}

.headingAccent {
  color: var(--color-base-06);
}

@media (--gte-tablet) {
  .heading {
    font-size: 40px;
  }
}

.description {
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 32px;
  max-width: 467px;
  margin-inline: auto;
}

.otherBrowsersHeading {
  font-weight: 500;
  font-size: 18px;
  margin-top: 32px;
  margin-bottom: 16px;
}

.otherBrowsersLinks {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.otherBrowsersLinksItem {
  padding: 16px;
  border: 1px solid var(--color-base-08);
  border-radius: 20px;
}
