@import '../core.css';

.fancyGradientText {
  background-clip: text;
  color: transparent;
}

.root {
  margin-inline: 8px;
  display: grid;
  justify-content: center;
  align-items: start;
  grid-template-areas:
    'heading'
    'image'
    'descriptionAndAppLink'
    'install';
}

@media (--gte-tablet) {
  .root {
    column-gap: 35px;
    grid-template-columns: repeat(2, 389px);
    grid-template-areas:
      'heading install'
      'descriptionAndAppLink install'
      'image image';
  }
}

@media (--gte-desktop) {
  .root {
    grid-template-columns: 467px 654px;
    column-gap: 160px;
  }
}

.heading {
  grid-area: heading;
  margin-block: 0;
  margin-inline: 16px;
  max-width: 312px;
  color: var(--color-base-99);
  font-weight: 500;
  font-size: 48px;
  line-height: 48px;
}

@media (--gte-tablet) {
  .heading {
    margin-inline: 20px;
    max-width: none;
    font-size: 56px;
    line-height: 64px;
  }
}

@media (--gte-desktop) {
  .heading {
    margin-inline: 0;
  }
}

.descriptionAndAppLink {
  grid-area: descriptionAndAppLink;
  margin-top: 16px;
  margin-inline: 16px;
  max-width: 312px;
}

@media (--gte-tablet) {
  .descriptionAndAppLink {
    margin-top: 24px;
    margin-inline: 20px;
    max-width: none;
  }
}

@media (--gte-desktop) {
  .descriptionAndAppLink {
    margin-top: 16px;
    margin-inline: 0;
  }
}

.description {
  margin: 0;
}

.appLink {
  margin-top: 24px;
  width: 100%;
}

@media (--gte-tablet) {
  .appLink {
    margin-top: 40px;
    width: auto;
  }
}

@media (--gte-desktop) {
  .appLink {
    margin-top: 24px;
  }
}

.install {
  grid-area: install;
  display: grid;
  row-gap: 32px;
  margin-top: 32px;
  margin-inline: 16px;
}

@media (--gte-tablet) {
  .install {
    margin-top: 0;
  }
}

@media (--gte-desktop) {
  .install {
    align-self: end;
    grid-template-columns: repeat(2, 298px);
    row-gap: 0;
    column-gap: 80px;
  }
}

.installBox {
  display: grid;
  row-gap: 16px;
}

@media (--gte-tablet) {
  .installBox {
    row-gap: 24px;
  }
}

.installBoxCta {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 16px;
  align-items: center;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: var(--color-base-99);
}

@media (--gte-tablet) {
  .installBoxCta {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 16px;
  }
}

.installBoxCta::before {
  content: '';
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background: var(--color-primary-50) no-repeat;
}

.installBoxCta_mobile::before {
  background-image: url(./mobile.svg);
  background-position: 24px 16px;
}

.installBoxCta_webExtension::before {
  background-image: url(./extension.svg);
  background-position: 20px 17px;
}

.installBoxCtaPart {
  display: block;
}

@media (--gte-tablet) {
  .installBoxCtaPart {
    display: inline;
  }
}

.installBoxCtaAccent {
  color: var(--color-primary-500);
}

.installBoxLinks {
  display: flex;
  gap: 16px;
}

.installLinkWebExtension {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
  padding: 12px 24px;
  border-radius: 16px;
  background: var(--color-primary-50);
  color: var(--color-primary-800);
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-decoration: underline;
}

.image {
  grid-area: image;
  margin-top: 16px;
  width: 344px;
  height: 240px;
  border-radius: 24px;
  background-color: var(--color-primary-300);
  background-image: url(./phone.webp), url(./tablet.webp), url(./glowing.svg);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position:
    197px 41px,
    -12px 8px,
    -380px -438px;
  background-size:
    144px 179px,
    340px 239px,
    1517px 1247px;
}

@media (--gte-tablet) {
  .image {
    margin-top: 32px;
    margin-inline: 10px;
    width: 795px;
    height: 320px;
    background-position:
      456px 11px,
      56px -10px,
      -152px -396px;
    background-size:
      241px 300px,
      555px 391px,
      1517px 1247px;
  }
}

@media (--gte-desktop) {
  .image {
    margin-top: 40px;
    margin-inline: 0;
    width: 1280px;
    height: 320px;
    background-position:
      724px -29px,
      128px -107px,
      -152px -396px;
    background-size:
      305px 380px,
      832px 586px,
      1517px 1247px;
  }
}
