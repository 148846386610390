@import '../core.css';

.fancyGradientText {
  background-clip: text;
  color: transparent;
}

.root {
  margin-inline: auto;
  width: 312px;
  text-align: center;
}

@media (--gte-tablet) {
  .root {
    width: 600px;
  }
}

.heading {
  margin-block: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: var(--color-base-99);
}

@media (--gte-tablet) {
  .heading {
    font-size: 40px;
    line-height: 38px;
  }
}

.description {
  margin-block: 16px 32px;
}

@media (--gte-tablet) {
  .description {
    margin: 32px auto;
    width: 467px;
  }
}

.otherBrowsers {
  display: none;
}

@media (--gte-desktop) {
  .otherBrowsers {
    display: block;
  }
}

.otherBrowsersHeading {
  margin-block: 48px 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--color-base-99);
}

.otherBrowsersLinks {
  display: grid;
  grid-template-columns: repeat(auto-fit, 64px);
  justify-items: center;
  justify-content: center;
  column-gap: 16px;
}

.otherBrowsersLinksItem {
  border: 1px solid var(--color-base-04);
  padding: 16px;
  border-radius: 20px;
}
