@import '../core.css';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  margin-inline: auto;
}

@media (--gte-tablet) {
  .content {
    flex-direction: row;
    justify-content: space-between;
    width: 834px;
    padding-inline: 40px;
    margin-inline: auto;
  }
}

@media (--gte-desktop) {
  .content {
    justify-content: space-between;
    width: 1440px;
    padding-inline: 128px;
    margin-inline: auto;
  }
}

.pictureBox {
  position: relative;
  order: 0;
}

.picture {
  position: relative;
  width: 344px;
  height: 100%;
}

@media (--gte-tablet) {
  .pictureBox {
    order: 2;
  }
  .picture {
    width: 462px;
    margin-inline-end: -40px;
  }
}

@media (--gte-desktop) {
  .picture {
    width: 520px;
    order: 2;
  }
}

.pictureShadow {
  display: none;
}

@media (--gte-desktop) {
  .pictureShadow {
    display: block;
    position: absolute;
    top: 44px;
    right: -102px;
    width: 242px;
    height: 242px;
    border-radius: 50%;
    background: var(--color-primary-500);
    filter: blur(150px);
  }
}

.info {
  display: flex;
  flex-direction: column;
  order: 1;
}

.heading,
.fancyHeading {
  position: relative;
  margin-block-start: -8px;
  margin-block-end: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: var(--color-base-99);
}

@media (--gte-tablet) {
  .heading,
  .fancyHeading {
    font-size: 40px;
    line-height: 48px;
    margin-block-start: 0;
  }
}

@media (--gte-desktop) {
  .heading,
  .fancyHeading {
    font-size: 48px;
    line-height: 56px;
  }
}

.fancyHeading {
  background: radial-gradient(
    102% 141% at 58px -4px,
    #22fff2 19.8%,
    #0a23fc 40.8%,
    #030f2f 67.25%,
    #fb36ff 87.34%
  );
  background-clip: text;
  color: transparent;
}

.description {
  margin-block-start: 16px;
  margin-block-end: 0;
  width: 312px;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-base-07);
}

@media (--gte-tablet) {
  .description {
    width: 349px;
  }
}

@media (--gte-desktop) {
  .description {
    width: 480px;
  }
}
