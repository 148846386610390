@import '../core.css';

.root {
  display: grid;
  grid-template-areas: 'illustration' 'content';
  max-width: 344px;
  margin-inline: auto;
  margin-top: 29px;
  margin-bottom: 80px;
  position: relative;
}

@media (--gte-tablet) {
  .root {
    grid-template-areas: 'illustration content';
    grid-template-columns: 1fr auto;
    margin-top: 88px;
    margin-bottom: 64px;
    max-width: 794px;
  }
}

@media (--gte-desktop) {
  .root {
    margin-top: 128px;
    max-width: 1184px;
  }
}

.content {
  grid-area: content;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (--gte-tablet) {
  .content {
    align-items: flex-start;
  }
}

.illustration {
  grid-area: illustration;
  position: relative;
}

@media (--gte-tablet) {
  .illustration {
    width: 480px;
    margin-left: -50px;
  }
}

@media (--gte-desktop) {
  .illustration {
    width: 624px;
  }
}

.shadow {
  display: none;
  position: absolute;
  width: 304px;
  height: 304px;
  left: 214px;
  top: 150px;
  background: #1f5af6;
  opacity: 0.64;
  filter: blur(200px);
}

@media (--gte-desktop) {
  .shadow {
    display: block;
  }
}

.fancyHeading {
  background: radial-gradient(
    153% 100% at 93px 5px,
    #22fff2 19.8%,
    #0a23fc 40.8%,
    #030f2f 67.25%,
    #fb36ff 87.34%
  );
  background-clip: text;
  color: transparent;
}
