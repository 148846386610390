@import '../core.css';

.root {
  display: grid;
  justify-items: center;
}

@media (--gte-tablet) {
  .root {
    grid-template-columns: 398px 350px;
    align-items: center;
    justify-content: center;
    column-gap: 26px;
    margin-inline: 20px 40px;
  }
}

@media (--gte-desktop) {
  .root {
    grid-template-columns: 560px 480px;
    align-items: start;
    margin-inline: 80px 128px;
    column-gap: 192px;
  }
}

.picture {
  width: 344px;
}

@media (--gte-tablet) {
  .picture {
    width: auto;
  }
}

.text {
  margin-block-start: -17px;
  width: 312px;
}

@media (--gte-tablet) {
  .text {
    margin-block-start: 0;
    width: auto;
  }
}

@media (--gte-desktop) {
  .text {
    margin-block-start: 152px;
  }
}

.heading {
  margin-block: 0;
  color: var(--color-base-99);
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
}

@media (--gte-tablet) {
  .heading {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (--gte-desktop) {
  .heading {
    font-size: 48px;
    line-height: 56px;
  }
}

.fancyGradientText {
  background: radial-gradient(
    180% 289% at 95px -4px,
    #22fff2 19.8%,
    #0a23fc 40.8%,
    #030f2f 67.25%,
    #fb36ff 87.34%
  );
  background-clip: text;
  color: transparent;
}

.description {
  margin-block: 16px 0;
}

@media (--gte-desktop) {
  .description {
    margin-block-start: 31px;
  }
}
