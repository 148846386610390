.overlay {
  isolation: isolate;
  position: fixed;
  inset: 0;
  background: var(--color-base-99);
  opacity: 0.64;
}

.overlayEntering {
  opacity: 0;
}

.overlayEntered,
.overlayExiting {
  transition-property: opacity;
  transition-timing-function: linear;
}

.overlayEntered {
  transition-duration: 200ms;
}

.overlayExiting {
  opacity: 0;
  transition-delay: 200ms;
  transition-duration: 200ms;
}

.overlayExited {
  visibility: hidden;
}

.content {
  isolation: isolate;
  position: absolute;
  inset: auto 8px 16px;
  margin: auto;
  padding: 16px;
  max-height: calc(100% - 32px);
  max-width: 480px;
  overflow: auto;
  border-radius: 24px;
  box-shadow: 8px 40px 80px -16px rgba(0, 0, 0, 0.16);
  background: var(--color-base-00);
  transform: translate3d(0, 0, 1px);
  transform-origin: bottom center;
}

.contentEntering,
.contentExiting {
  transform: translate3d(0, 100%, 1px) scale3d(1, 0, 1);
}

.contentEntered,
.contentExiting {
  transition-property: transform;
}

.contentEntered {
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
}

.contentExiting {
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.36, 0, 0.66, -0.56);
}

.contentExited {
  visibility: hidden;
}
