.icon {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  transform: scale(1);
  transition-property: opacity, transform;
  transition-duration: 200ms;
}

.icon:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.icon:active {
  transform: scale(1);
  opacity: 0.6;
}
