@import '../core.css';

.heading {
  font-weight: 500;
  font-size: 32px;
  margin-block: 0;
  color: var(--color-base-99);
}

@media (--gte-tablet) {
  .heading {
    font-size: 40px;
  }
}

@media (--gte-desktop) {
  .heading {
    font-size: 48px;
  }
}

.description {
  font-size: 16px;
  color: var(--color-base-07);
  margin-top: 16px;
  margin-bottom: 32px;
}
