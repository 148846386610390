@import '../core.css';

.card {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 40px;
  align-items: start;
  justify-content: space-around;
  padding: 40px 24px;
  margin-inline: auto;
  width: 344px;
  background: linear-gradient(
    282.56deg,
    var(--color-base-00) 11.29%,
    var(--color-base-01) 92.62%
  );
  box-shadow: 8px 40px 80px rgba(0, 0, 0, 0.16);
  border-radius: 40px;
}

@media (--gte-tablet) {
  .card {
    grid-template-columns: repeat(4, auto);
    padding: 56px 50px;
    width: 794px;
  }
}

@media (--gte-desktop) {
  .card {
    padding: 64px 120px;
    width: 1184px;
  }
}

.box {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 24px;
}

.term {
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-base-06);
}

.description {
  width: fit-content;
  background-clip: text;
  color: transparent;
  background-image: radial-gradient(
    90% 90% at 20% 10%,
    #22fff2 19.8%,
    #0a23fc 40.8%,
    #030f2f 67.25%,
    #fb36ff 87.34%
  );
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
}
