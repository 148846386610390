@import '../core.css';

.background {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pillBox {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.pillBoxRight {
  justify-content: end;
}

.pillUsdt {
  position: absolute;
  right: 50%;
  margin-right: 56px;
  width: 100px;
  height: 100px;
}

@media (--gte-tablet) {
  .pillUsdt {
    top: 9px;
    margin-right: 174px;
    width: 195px;
    height: 88px;
  }
}

@media (--gte-desktop) {
  .pillUsdt {
    top: 0;
    margin-right: 322px;
    width: 222px;
    height: 100px;
  }
}

.pillBtc {
  position: absolute;
  top: 40px;
  left: 50%;
  margin-left: 72px;
  width: 100px;
  height: 100px;
}

@media (--gte-tablet) {
  .pillBtc {
    top: 0;
    margin-left: 181px;
    width: 190px;
    height: 88px;
  }
}

@media (--gte-desktop) {
  .pillBtc {
    top: 40px;
    margin-left: 247px;
    width: 224px;
    height: 104px;
  }
}

.pillBusd {
  position: absolute;
  top: 233px;
  right: 50%;
  margin-right: 87px;
  width: 112px;
  height: 112px;
}

@media (--gte-tablet) {
  .pillBusd {
    top: 258px;
    margin-right: 186px;
    width: 203px;
    height: 96px;
  }
}

@media (--gte-desktop) {
  .pillBusd {
    top: 212px;
    margin-right: 403px;
    width: 237px;
    height: 112px;
  }
}

.pillWaves {
  position: absolute;
  top: 265px;
  left: 50%;
  margin-left: 96px;
  width: 112px;
  height: 112px;
}

@media (--gte-tablet) {
  .pillWaves {
    top: 246px;
    margin-left: 175px;
    width: 215px;
    height: 96px;
  }
}

@media (--gte-desktop) {
  .pillWaves {
    top: 156px;
    margin-left: 389px;
    width: 251px;
    height: 112px;
  }
}

.pillSwop {
  position: absolute;
  top: 352px;
  left: 50%;
  margin-left: 28px;
  width: 96px;
  height: 120px;
}

@media (--gte-tablet) {
  .pillSwop {
    top: 427px;
    margin-left: 78px;
    width: 210px;
    height: 104px;
  }
}

@media (--gte-desktop) {
  .pillSwop {
    top: 335px;
    margin-left: 152px;
    width: 242px;
    height: 120px;
  }
}

.pillEth {
  position: absolute;
  top: 369px;
  right: 50%;
  margin-right: 20px;
  width: 96px;
  height: 120px;
}

@media (--gte-tablet) {
  .pillEth {
    top: 400px;
    margin-right: 2px;
    width: 188px;
    height: 104px;
  }
}

@media (--gte-desktop) {
  .pillEth {
    top: 296px;
    margin-right: 150px;
    width: 218px;
    height: 120px;
  }
}

.pill {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  height: fit-content;
  background: var(--color-base-00);
  box-shadow: 12px 32px 80px -20px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
}

.pillLogo,
.pillShadowLogo {
  width: 72px;
  height: 72px;
}

@media (--gte-tablet) {
  .pillLogo,
  .pillShadowLogo {
    width: 60px;
    height: 60px;
  }
}

@media (--gte-desktop) {
  .pillLogo,
  .pillShadowLogo {
    width: 72px;
    height: 72px;
  }
}

.pillLogo {
  position: relative;
  border-radius: 22px;
}

.pillLogoWithBorder {
  border: 1px solid var(--color-base-04);
}

.pillText {
  display: none;
}

@media (--gte-tablet) {
  .pillText {
    display: block;
    padding-inline-start: 22px;
    padding-inline-end: 32px;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-base-99);
  }
}

@media (--gte-desktop) {
  .pillText {
    font-size: 24px;
    line-height: 32px;
  }
}

.pillShadow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  bottom: 0px;
  right: 0px;
  width: fit-content;
  border: 0.604428px solid #e0e0ee;
  border-radius: 24px;
}

.pillShadowText {
  display: none;
}

@media (--gte-tablet) {
  .pillShadowText {
    display: block;
    padding-inline-start: 22px;
    padding-inline-end: 32px;
    font-size: 20px;
    line-height: 24px;
    color: transparent;
  }
}

.pillShadowLeft {
  right: none;
  left: 0;
}

.heading,
.fancyHeading {
  position: relative;
  margin-block-start: 125px;
  margin-block-end: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: var(--color-base-99);
}

@media (--gte-tablet) {
  .heading,
  .fancyHeading {
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    margin-block-start: 118px;
  }
}

@media (--gte-desktop) {
  .heading,
  .fancyHeading {
    margin-block-start: 84px;
  }
}

.fancyHeading {
  background: radial-gradient(
    146% 110% at 66px 3px,
    #22fff2 19.8%,
    #0a23fc 40.8%,
    #030f2f 67.25%,
    #fb36ff 87.34%
  );
  background-clip: text;
  color: transparent;
}

.description {
  margin-block-start: 16px;
  margin-block-end: 0;
  width: 328px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--color-base-07);
}

@media (--gte-tablet) {
  .description {
    margin-block-start: 24px;
    width: 450px;
  }
}

.appLink {
  margin-block-start: 32px;
  margin-block-end: 172px;
}

@media (--gte-tablet) {
  .appLink {
    margin-block-end: 194px;
  }
}
