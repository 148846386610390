@import './fonts.css';
@import 'normalize.css';

:root {
  --color-base-00: #ffffff;
  --color-base-01: #f9f9fd;
  --color-base-02: #f4f4f9;
  --color-base-03: #eaeaf3;
  --color-base-04: #e0e0ee;
  --color-base-05: #c6c6de;
  --color-base-06: #9c9cb6;
  --color-base-07: #6e6e81;
  --color-base-08: #424250;
  --color-base-09: #272732;
  --color-base-99: #000000;

  --color-primary-50: #e9efff;
  --color-primary-100: #b6c7f8;
  --color-primary-200: #a1affc;
  --color-primary-300: #768ffb;
  --color-primary-400: #5173f9;
  --color-primary-500: #1f5af6;
  --color-primary-600: #184fea;
  --color-primary-700: #0044dd;
  --color-primary-800: #0039d2;
  --color-primary-900: #0021c1;

  --max-width: 1280px;
}

* {
  accent-color: var(--color-primary-500);
  -webkit-tap-highlight-color: transparent;
  overflow-wrap: anywhere;
}

*:focus-visible {
  outline: 2px solid var(--color-primary-200);
  outline-offset: 1px;
}

html,
body {
  height: 100%;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  min-width: 320px;
  background: var(--color-base-01);
  color: var(--color-base-07);
  font:
    1em / calc(24 / 16) Rubik,
    Frutiger,
    'Frutiger Linotype',
    Univers,
    Calibri,
    'Gill Sans',
    'Gill Sans MT',
    'Myriad Pro',
    Myriad,
    'DejaVu Sans Condensed',
    'Liberation Sans',
    'Nimbus Sans L',
    Tahoma,
    Geneva,
    'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif;
  font-feature-settings: 'liga' off;
}

img,
svg {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  font: inherit;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

:any-link {
  color: var(--color-primary-500);
  text-decoration: none;
}
