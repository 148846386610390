@import '../core.css';

.root {
  isolation: isolate;
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
}

.header {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  padding: 12px 24px;
}

@media (--gte-tablet) {
  .header {
    padding: 16px 40px;
  }
}

@media (--gte-desktop) {
  .header {
    grid-template-columns: 1fr auto auto;
    margin: auto;
    padding: 40px 0;
    width: var(--max-width);
  }
}

.headerLogo {
  justify-self: start;
}

.headerLogoImg {
  width: 90px;
}

@media (--gte-tablet) {
  .headerLogoImg {
    width: 120px;
  }
}

.burgerMenuOpenButton {
  justify-self: end;
  border: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  background: url(./menu.svg) no-repeat;
}

.burgerMenuHeader {
  display: flex;
}

.burgerMenuCloseButton {
  margin-inline-start: auto;
  border: 0;
  padding: 12px;
  border-radius: 16px;
  background: var(--color-primary-50);
}

.burgerMenuNav {
  display: grid;
  row-gap: 12px;
  margin-block: 32px;
}

.burgerMenuItem {
  padding: 16px;
  border-radius: 16px;
  background: var(--color-base-01);
  color: var(--color-base-99);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;
}

.burgerMenuAppLink {
  width: 100%;
}

@media (--gte-desktop) {
  .burgerMenuOpenButton,
  .burgerMenu {
    display: none;
  }
}

.menu,
.headerEnd {
  display: none;
}

@media (--gte-desktop) {
  .menu {
    display: flex;
  }

  .headerEnd {
    display: block;
  }
}

.menu {
  align-items: center;
  gap: 8px;
}

.menuItem {
  padding: 4px 16px;
  color: var(--color-base-99);
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.headerEnd {
  margin-inline-start: auto;
}

.main {
  padding: 20px 0 64px;
}

@media (--gte-tablet) {
  .main {
    padding: 14px 0 80px;
  }
}

@media (--gte-desktop) {
  .main {
    padding: 20px 0 128px;
  }
}

.footer::before {
  content: '';
  display: block;
  height: 4px;
  background: linear-gradient(93.58deg, #8304ff -9.92%, #1878fb 104.08%);
}

.footerContent {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'logo mainNav'
    'sideNav mainNav'
    'socialNav socialNav'
    'legalNav legalNav'
    'copyright copyright';
  row-gap: 48px;
  margin: auto;
  padding: 48px 24px;
}

@media (--gte-tablet) {
  .footerContent {
    grid-template-columns: 1fr repeat(2, 195px);
    grid-template-areas:
      'logo mainNav sideNav'
      'logo socialNav socialNav'
      'copyright legalNav legalNav';
    padding-inline: 40px;
  }
}

@media (--gte-desktop) {
  .footerContent {
    grid-template-areas:
      'logo mainNav sideNav'
      'logo mainNav socialNav'
      'copyright legalNav legalNav';
    padding: 132px 48px;
    max-width: var(--max-width);
  }
}

.footerLogo {
  grid-area: logo;
  align-self: start;
  justify-self: start;
}

.footerLogoImg {
  width: 120px;
}

.footerMainNav {
  align-self: start;
  grid-area: mainNav;
  display: grid;
  row-gap: 24px;
  font-weight: 500;
  letter-spacing: -0.01em;
}

@media (--gte-desktop) {
  .footerMainNav {
    row-gap: 15px;
  }
}

.footerMainNavLink {
  color: var(--color-base-99);
}

.footerSideNav {
  grid-area: sideNav;
  align-self: end;
  justify-self: start;
  display: grid;
  row-gap: 24px;
}

@media (--gte-tablet) {
  .footerSideNav {
    align-self: start;
  }
}

@media (--gte-desktop) {
  .footerSideNav {
    row-gap: 12px;
  }
}

.footerSideNavLink {
  color: var(--color-base-09);
}

.footerSocialNav {
  grid-area: socialNav;
  justify-self: start;
  display: grid;
  grid-template-columns: repeat(4, 32px);
  column-gap: 32px;
}

@media (--gte-desktop) {
  .footerSocialNav {
    align-self: end;
    grid-template-columns: repeat(4, 24px);
    column-gap: 20px;
  }
}

.footerLegalNav {
  grid-area: legalNav;
  justify-self: start;
  display: grid;
  row-gap: 24px;
}

@media (--gte-tablet) {
  .footerLegalNav {
    display: contents;
  }
}

.footerLegalNavLink {
  color: var(--color-base-07);
}

.copyright {
  grid-area: copyright;
  font-style: normal;
}
