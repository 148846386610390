@import '../core.css';

.root {
  margin-inline: 24px;
  max-width: var(--max-width);
}

@media (--gte-tablet) {
  .root {
    margin-inline: 40px;
  }
}

@media (--gte-desktop) {
  .root {
    margin-inline: auto;
  }
}

.inner {
  max-width: 880px;
}

.root :is(h1, h2, h3, h4, h5, h6) {
  color: var(--color-base-99);
  line-height: 1.2;
}

.root h1 {
  font-weight: 500;
  font-size: 2.5em;
}

.root h2 {
  font-size: 2em;
}

.root h3 {
  font-size: 1.5em;
}

.root p {
  margin-block: 0;
  line-height: 1.5;
}

.root p:not(:first-child) {
  margin-block-start: 1.4em;
}

.root ul {
  margin: 0;
  padding-inline-start: 32px;
  list-style: none;
}

.root ul:not(:first-child) {
  margin-block-start: 0.75em;
}

.root ul li {
  position: relative;
}

.root ul li + li {
  margin-block-start: 1.25em;
}

.root ul li::before {
  content: '';
  position: absolute;
  inset: 8px 100% auto auto;
  border: 2px solid #1f5af6;
  margin-inline-end: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.root table {
  border-spacing: 0;
  border: 1px solid var(--color-base-03);
  border-radius: 4px;
}

.root table * {
  overflow-wrap: normal;
}

.root :is(th, td) {
  padding: 20px;
  vertical-align: baseline;
}

.root :is(th, td):not(:last-child) {
  border-inline-end: 1px solid var(--color-base-03);
}

.root tbody tr :is(th, td) {
  border-block-start: 1px solid var(--color-base-03);
}

.root th {
  background: var(--color-base-02);
  font-weight: normal;
  text-align: start;
}

.root .lastUpdate {
  margin-block: 32px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-base-09);
}

@media (--gte-tablet) {
  .root .lastUpdate {
    margin-block: 40px;
  }
}
