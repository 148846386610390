@import '../core.css';

.fancyGradientText {
  background-clip: text;
  color: transparent;
}

.root {
  display: grid;
  margin-inline: auto;
  padding: 40px 20px;
  width: 344px;
  border-radius: 40px;
  box-shadow: 8px 40px 80px -16px rgba(0, 0, 0, 0.16);
  background: linear-gradient(282.56deg, #ffffff 11.29%, #f9f9fd 92.62%);
  text-align: center;
}

@media (--gte-tablet) {
  .root {
    padding: 64px;
    width: 794px;
  }
}

@media (--gte-desktop) {
  .root {
    padding: 80px;
    width: 1184px;
    background-image: url(./installMobileAppBg.svg);
  }
}

.heading {
  margin-block: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: var(--color-base-99);
}

@media (--gte-tablet) {
  .heading {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (--gte-desktop) {
  .heading {
    font-size: 48px;
    line-height: 56px;
  }
}

.description {
  margin-block: 24px 32px;
}

.links {
  display: flex;
  gap: 16px;
  justify-content: center;
}
