@import '../core.css';

.root {
  max-width: 344px;
  margin-inline: auto;
  margin-top: 64px;
  padding-top: 40px;
  padding-inline: 16px;
  text-align: center;
  background: var(--color-base-00);
  box-shadow: 8px 40px 80px -16px rgba(0, 0, 0, 0.16);
  border-radius: 40px;
  overflow: hidden;
  position: relative;
}

@media (--gte-tablet) {
  .root {
    max-width: 794px;
    margin-top: 0;
    padding-top: 64px;
    padding-inline: 20px;
  }
}

@media (--gte-desktop) {
  .root {
    max-width: 1184px;
    margin-top: 133px;
  }
}

.content {
  z-index: 1;
  position: relative;
}

.illustration {
  margin-inline: auto;
  margin-top: 40px;
}

@media (--gte-tablet) {
  .illustration {
    margin-top: 48px;
  }

  .description {
    max-width: 467px;
    margin-inline: auto;
  }
}

@media (--gte-desktop) {
  .illustration {
    max-width: 818px;
  }
}

.pinkShadow {
  display: none;
  position: absolute;
  width: 364px;
  height: 364px;
  left: 13px;
  top: 410px;
  background: #fb36ff;
  opacity: 0.32;
  filter: blur(200px);
}

.blueShadow {
  display: none;
  position: absolute;
  width: 364px;
  height: 364px;
  left: 871px;
  top: 240px;
  background: #1f5af6;
  opacity: 0.32;
  filter: blur(200px);
}

@media (--gte-desktop) {
  .pinkShadow,
  .blueShadow {
    display: block;
  }
}

.fancyHeading {
  background: radial-gradient(
    84% 236% at 60px -10px,
    #22fff2 19.8%,
    #0a23fc 40.8%,
    #030f2f 67.25%,
    #fb36ff 87.34%
  );
  background-clip: text;
  color: transparent;
}
