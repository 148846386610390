@import '../core.css';

.root {
  display: grid;
  grid-template-areas: 'illustration' 'content';
  max-width: 344px;
  margin-inline: auto;
}

@media (--gte-tablet) {
  .root {
    grid-template-areas: 'illustration content';
    grid-template-columns: 1fr auto;
    max-width: 794px;
  }
}

@media (--gte-desktop) {
  .root {
    max-width: 1184px;
  }
}

.content {
  grid-area: content;
  max-width: 382px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.installationLinks {
  display: flex;
  gap: 16px;
}

.illustration {
  grid-area: illustration;
  position: relative;
}

.pictureShadow {
  position: absolute;
  display: none;
  width: 364px;
  height: 364px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  background: #fb36ff;
  opacity: 0.64;
  filter: blur(200px);
}

@media (--gte-desktop) {
  .pictureShadow {
    display: block;
  }
}

.illustrationMobile {
  margin-left: 0;
}

@media (--gte-tablet) {
  .illustrationMobile {
    margin-left: -20px;
  }
}

.illustrationDesktop {
  display: none;
}

@media (--gte-desktop) {
  .illustrationMobile {
    display: none;
  }

  .illustrationDesktop {
    display: block;
  }
}

.fancyHeading {
  background: radial-gradient(
    123% 250% at 10px -10px,
    #22fff2 19.8%,
    #0a23fc 40.8%,
    #030f2f 67.25%,
    #fb36ff 87.34%
  );
  background-clip: text;
  color: transparent;
  transform: rotate(50);
}
