@import '../core.css';

.root {
  overflow: hidden;
}

.container {
  display: grid;
  grid-template-areas: 'illustration' 'content';
  max-width: 344px;
  margin-inline: auto;
  margin-top: 29px;
  padding-bottom: 29px;
  position: relative;
}

@media (--gte-tablet) {
  .container {
    grid-template-areas: 'content illustration';
    grid-template-columns: 1fr 1fr;
    max-width: 794px;
    margin-top: 88px;
    padding-bottom: 88px;
  }
}

@media (--gte-desktop) {
  .container {
    margin-top: 128px;
    max-width: 1184px;
    padding-bottom: 128px;
  }
}

.content {
  grid-area: content;
  max-width: 382px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (--gte-tablet) {
  .content {
    align-items: flex-start;
  }
}

.illustration {
  grid-area: illustration;
}

.pictureShadow {
  display: none;
  position: absolute;
  width: 242px;
  height: 242px;
  left: 134px;
  top: 0;
  background: #1f5af6;
  opacity: 0.21;
  filter: blur(150px);
}

@media (--gte-desktop) {
  .pictureShadow {
    display: block;
  }
}

.illustrationPicture {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 40px;
}

@media (--gte-tablet) {
  .illustrationPicture {
    margin-right: -350px;
    max-width: 1076px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    margin-bottom: 0;
  }
}

@media (--gte-desktop) {
  .illustrationPicture {
    box-shadow: 0 40px 80px rgba(0, 0, 0, 0.15);
  }
}

.fancyHeading {
  background: radial-gradient(
    170% 273% at 57px -45px,
    #22fff2 19.8%,
    #0a23fc 40.8%,
    #030f2f 67.25%,
    #fb36ff 87.34%
  );
  background-clip: text;
  color: transparent;
}
